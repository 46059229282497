<template>
  <v-container>
    <div class="d-flex">
      <h2>Daily Journal Summary</h2>
      <v-spacer></v-spacer>
      <v-btn small color="success" @click="refresh">Refresh</v-btn>
    </div>

    <v-data-table
      v-if="!loading"
      :items="journal_entries"
      :headers="journal_headers"
      :loading="loading"
    >
      <!-- for debit and credit entries -->
      <template v-slot:item.credit="{ item }">
        <span v-if="!item.is_debit">{{ item.amount }}</span>
      </template>
      <template v-slot:item.debit="{ item }">
        <span v-if="item.is_debit">{{ item.amount }}</span>
      </template>

      <!-- colors of account title -->
      <template v-slot:item.account_title="{ item }">
        <v-chip
          color="success"
          v-if="item.chart_of_account.account_title == 'Cash'"
          >{{ item.chart_of_account.account_title }}</v-chip
        >

        <v-chip
          color="orange"
          v-else-if="
            item.chart_of_account.account_title == 'Accounts Receivables'
          "
          >{{ item.chart_of_account.account_title }}</v-chip
        >
        <v-chip
          color="yellow"
          v-else-if="item.chart_of_account.account_title == 'Service Revenue'"
          >{{ item.chart_of_account.account_title }}</v-chip
        >

        <v-chip
          color="primary"
          v-else-if="item.chart_of_account.account_title == 'Sales/Item Sales'"
          >{{ item.chart_of_account.account_title }}</v-chip
        >

        <v-chip
          color="primary"
          v-else-if="item.chart_of_account.account_title == 'Gcash'"
          >{{ item.chart_of_account.account_title }}</v-chip
        >

        <v-chip
          color="error"
          v-else-if="item.chart_of_account.account_title == 'Software Revenues'"
          >{{ item.chart_of_account.account_title }}</v-chip
        >

        <v-chip color="grey" v-else>
          {{ item.chart_of_account.account_title }}
        </v-chip>
      </template>
      <!-- for invoice id -->
      <template v-slot:item.invoice_id="{ item }">
        <v-chip :color="getColor(item.invoice_id)">{{
          item.invoice_id
        }}</v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn small icon color="red" @click="del(item)" :loading="loading"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <h2>
      Total Debit:
      <span class="success--text">{{ total_debit_credit.debit }}</span> | Total
      Credit:
      <span class="primary--text"> {{ total_debit_credit.credit }}</span>
    </h2>
    <v-btn class="mt-4" color="primary" @click="post_journal" :loading="loading"
      >Post Journal</v-btn
    >
  </v-container>
</template>

<script>
// import { mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      invoice_id_colors_collection: {},
      loading: false,
      journal_entries: [],
      journal_headers: [
        { text: "Action", value: "action" },
        { text: "Journal ID", value: "id" },
        { text: "Account Title", value: "account_title" },
        { text: "Debit", value: "debit" },
        { text: "Credit", value: "credit" },
        { text: "Date", value: "created_at" },
        { text: "Remarks", value: "remarks" },
        { text: "Invoice ID", value: "invoice_id" },
        { text: "Is equal", value: "is_equal" },
      ],
    };
  },
  created() {
    this.get_daily_journals();
    this.ready = true;
  },
  computed: {
    total_debit_credit() {
      return this.journal_entries.reduce(
        (totals, entry) => {
          if (entry.is_debit) {
            totals.debit += entry.amount;
          } else {
            totals.credit += entry.amount;
          }
          return totals;
        },
        { credit: 0, debit: 0 }
      );
    },
  },
  methods: {
    getColor(targetId) {
      console.log("triggered");
      const foundObject = this.invoice_id_colors_collection.find(
        (obj) => obj.id === targetId
      );
      return foundObject.color;
    },
    async get_daily_journals() {
      this.loading = true;
      let response = await axios.get("/get_daily_journals");
      console.log("daily journals", response);
      this.journal_entries = response.data;
      this.loading = false;

      this.get_collection_of_invoice_id();
      // let x = this.getColorById(this.invoice_id_colors_collection, 1634);
    },

    combineArraysToObject(a) {
      // Initialize an empty array to store the result
      let result = [];
      // Iterate through array 'a' and create objects
      for (let i = 0; i < a.length; i++) {
        let color = i % 2 === 0 ? "primary" : "error";
        result.push({ id: a[i], color: color });
      }

      return result;
    },

    get_collection_of_invoice_id() {
      var collected_id = [];

      // get the first id
      let last_index = this.journal_entries.length - 1;
      let first_id = this.journal_entries[last_index]["invoice_id"];

      collected_id.push(first_id);

      this.journal_entries.forEach((j) => {
        if (!collected_id.includes(j.invoice_id))
          collected_id.push(j.invoice_id);
      });
      let x = this.combineArraysToObject(collected_id);
      this.invoice_id_colors_collection = x;
    },
    refresh() {
      this.get_daily_journals();
    },
    async del(item) {
      this.loading = true;
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        await axios.delete("/journal_entry/" + item.id);
        let filtered = this.journal_entries.filter((c) => {
          return c.id != item.id;
        });
        alert("Successfully deleted an item");
        this.journal_entries = filtered;
      }
      this.loading = false;
      // return item;
    },
    async post_journal() {
      if (this.total_debit_credit.debit != this.total_debit_credit.credit)
        return alert(
          "ERROR: Debit and Credit is not equal. Please investigate"
        );
      this.loading = true;
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        let journal_id = [];
        this.journal_entries.forEach((j) => {
          journal_id.push(j.id);
        });
        let request = { journal_id: journal_id };
        await axios.post("/post_daily_journal_entry", request);
        let response = await axios.get("/get_daily_journals");
        this.journal_entries = response.data;
        alert("Successfully posted daily journals");
      }
      this.loading = false;
    },
  },
};
</script>

<style></style>
